
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;800&display=swap');

.toprefer-main{
    width: 100%;
    min-height: 50vh;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
}

.toprefer-main h1 {
    font-size: 2.3rem;
    font-weight: bold;
    color: #F86222;
}

.toprefer-main  .slider-card .swiper {
    width: 100%;
    height: 100%;
    margin: 20px 0px;
}

.toprefer-main .slider-card .swiper-slide {
    text-align: center;
    font-size: 30px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.toprefer-main .slider-card .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.toprefer-main .slider-card .card-1{
    height: 300px;
    background-color: white;
    width: 320px;
    border-radius: 100%;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    overflow: hidden;
    overflow-wrap: break-word;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.toprefer-main .slider-card .card-1 img{
    border-radius: 50%;
    height: 100px;
    width: 100px;
}

.toprefer-main .slider-card .card-1 h2{
    color: black;
    font-size: 1.2rem;
    letter-spacing: 2px;
    font-weight: bold;
}

.toprefer-main .slider-card .card-1 h3{
    font-size: 1.5rem;
    letter-spacing: 2px;
}

.toprefer-main .slider-card .card-1 h3 span{
    color: #F86222;
    font-weight: bold;
}

