 @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;800&display=swap');


.navbar{
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    font-family: 'Poppins', sans-serif;

}

.navbar .logo-unity{
    height: 85px;
    border-radius: 50px;
    cursor: pointer;
}

.navbar h2{
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer;
}

.navbar .button-nav button{
    /* background-color: #F74F22; */
    background: white;
    border: 0.2rem solid #F74F22;
    color: #F74F22;
    font-weight: bold;
    transition: 0.3s ease-in;
}

.navbar .button-nav button:hover{
    background-color: #F74F22;
    color: white;

}
.navbar .button-nav a{
    /* background-color: #F74F22; */
    background: white;
    border: 0.2rem solid #F74F22;
    color: #F74F22;
    font-weight: bold;
    transition: 0.3s ease-in;
}

.navbar .button-nav a:hover{
    background-color: #F74F22;
    color: white;

}