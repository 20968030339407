@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;800&display=swap');

.form-main{
    width: 100%;
    min-height: 69vh;
    background: white;
    font-family: 'Poppins', sans-serif;
}
.form-main h1{
    font-size: 2rem;
}

.form-main label{
    color: black;
    font-size: 1.1rem;
}

.form-main input{
    height: 45px;
}

.form-main button{
    background: white;
    border: 0.2rem solid #F74F22;
    color: #F74F22;
    font-weight: bold;
    transition: 0.3s ease-in;
    width: 100px;
    height: 40px;
    border-radius: 5px;
}
.form-main button:hover{
    background-color: #F74F22;
    color: white;
}