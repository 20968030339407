@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;800&display=swap');

.hero-main{
    min-height: 90vh;
    font-family: 'Poppins', sans-serif;
}

.hero-main .content h2{
    font-size: 3.2rem;
    color: white;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 4px;
    font-weight:  bold;
    -webkit-text-stroke-width: 2px; 
   -webkit-text-stroke-color: rgb(0, 0, 0);
}

.hero-main .content h4{
    color: white;
    font-size: 1.1rem;
    line-height: 21px;
}

.hero-main .content button{
    background-color: #F74F22;
    border: none;
    /* height: 50px; */
    /* width: 200px; */
    font-size: 1.3rem;
    font-weight: 500;
    padding: 13px;
}

.hero-main .content .cart1{
    height: 90px;
    background-color: #F74F22;
    border-radius: 4px;
}

.hero-main .content .cart2{
    height: 90px;
    background-color: white;
    border: 0.2rem solid #F74F22;
    color: #F74F22;
    border-radius: 4px;
}

.hero-main .content .cart1 h4{
    font-size: 1.4rem;
}

.hero-main .content .cart1 h3{
    color: white;
    font-weight: bold;
}

.hero-main .content .cart2 h4{
    font-size: 1.4rem;
    color: #F74F22;

}

.hero-main .content .cart2 h3{
    color: #F74F22;
    font-weight: bold;
}