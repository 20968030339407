@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;800&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.content-main{
    width: 100%;
    min-height: 60vh;
    background: white;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 10px;
}

.content-main img{
    height: 450px;
    border-radius: 10px;
}

.content-main .handing-line h4{
    /* border-bottom: 0.2rem solid black; */
    color: #F74F22;
    font-size: 20px;
    font-family: monospace;
}

.content-main .content-box h2{
    /* border-bottom: 0.2rem solid black; */
    color: black;
    font-size: 1.9rem;
    font-weight: bold;
    letter-spacing: 2px;
}

.content-main .content-box img{
    height: 7px;
}

.content-main .content-box p{
    color: rgb(46, 45, 45);
    font-size: 17px;
    font-family: "Roboto", sans-serif;
}