@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;800&display=swap');

.footer-main{
    background-color: #070E20;
    width: 100%;
    min-height: 90px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.footer-main h2{
    color: white;
    font-size: 1.2rem;
}

.footer-main h3{
    color: rgb(203, 199, 199);
    font-size: 1rem;
}