@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;800&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");


.Flieldbox-main{
    width: 100%;
    height: auto;
    background-color: white;
    font-family: 'Poppins', sans-serif;
}

.Flieldbox-main .box-main{
    background-color: white;
    min-height: 450px;
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.Flieldbox-main .box-main h2{
    font-size: 1.7rem;
    font-weight: bold;
    color: #F86222;
}

.Flieldbox-main .box-main p{
    color: rgb(46, 45, 45);
    font-size: 17px;
    font-family: "Roboto", sans-serif;
}

.Flieldbox-main .box-main .fields label{
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 1px;
    font-family: "Roboto", sans-serif;
}

.Flieldbox-main .box-main .fields button{
    background-color: #F86222;
    border: 3px solid #F86222;
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
    transition: 0.3s ease;
}

.Flieldbox-main .box-main .fields button:hover{
    background-color: white;
    color: #F86222;
}