@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.cart2-main {
  background: white;
  width: 100%;
  min-height: 88vh;
  font-family: "Poppins", sans-serif;
  /* color: black; */
  /* margin-top: 230px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart2-main h4 {
  color: #f74f22;
  font-size: 20px;
  font-family: monospace;
}

.cart2-main h5 {
  color: #f74f22;
  font-size: 1.4rem;
  font-weight: bold;
}

.cart2-main h2 {
  font-size: 2.1rem;
  font-weight: 600;
}

.cart2-main .cart2-box .box {
  background-color: white;
  height: 470px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  overflow: hidden;
}

.cart2-main .cart2-box .box img {
  height: 200px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.cart2-main .cart2-box .box h3 {
  font-size: 1.2rem;
  font-weight: 600;
}

.cart2-main .cart2-box .box p {
  font-size: 14px;
  color: rgb(46, 45, 45);
  font-family: "Roboto", sans-serif;
}

.cart2-main .cart2-box .box h4 {
  color: #f74f22;
  font-size: 2rem;
  font-weight: bold;
}

.cart2-box button {
    background-color: #F86222;
    border: 3px solid #F86222;
    color: white;
    transition: 0.3s ease;
    font-size: 1.1rem;
}
.cart2-box button:active{
    background-color: #F86222;
    border: 3px solid #F86222;
    color: white;
    transition: 0.2s ease;
    font-size: 1.1rem;
}

.cart2-box button:hover {
    background-color: white;
    color: #F86222;
    border: 3px solid #F86222;
}

.cart2-main li {
  color: #f74f22;
  font-size: 1.3rem;
}

.cart2-box .cart2-box h4 {
  position: fixed;
  z-index: 1000;
}
