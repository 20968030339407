@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;800&display=swap');

.cart-main{
    width: 100%;
    min-height: auto;
    background-color: #070E20;
    font-family: 'Poppins', sans-serif;
}

.cart-main h2{
    color: white;
    font-size: 1.9rem;
    font-weight: bold;
    letter-spacing: 2px;
}

.cart-main .box-2{
    /* background: url("../assiets//img/heroimg.jpg");
    background-position: center;
    background-size: cover; */
    height: 380px;
    /* width: 100%; */
}